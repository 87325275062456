import React,{useState} from 'react'
import Link from 'gatsby'
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-two'
import BlockContent from '@sanity/block-content-to-react'
import Hero from "./product/hero";
import {Container, Row, Col} from '../components/ui/wrapper'

import {ArticleImage,ArticleH1,ArticleIntro,ArticleContent,ArticleImageText,Aside,AsideTransparentBorder} from "./article.style"
import Assurance from "./product/assurance"
import { CaseStudyTextWrap } from "./product/assurance/assurance.style"
import Heading from "../components/ui/heading"
import Text from "../components/ui/text"
import AboutServiceWrap from "../containers/index/about-service-wrap"
import AboutArea from "../containers/index/about-area"
import { graphql, useStaticQuery } from "gatsby"
import Aggregator from "./product/aggregator"
import ProductForm from "../components/forms/product-form"




const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

const Provider = ({location,pageContext}) =>{

  const [formActive,setFormActive] = useState(false)

  const data = useStaticQuery(graphql`
        query
            {
            products: allSanityGuaranteeProduct(filter: {active: {eq: true}}, sort: {fields: name, order: ASC}){
                edges{
                    node{
                        _id
                          explanation
                          name
                          bygg
                          slug {
                            current
                          }
                          image {
                            asset {
                              fixed(width:225, height:145){
                                    ...GatsbySanityImageFixed
                                }
                            }
                          }
                          introduction
                          industry {
                            name
                          }
                          provider{
                            name,
                            introduction,
                            logo{
                              asset {
                                fixed(width:200){
                                      ...GatsbySanityImageFixed
                                  }
                              }
                            },
                            can_use_logo,
                            slug{
                              current
                            },
                            homepage
                          }
                          substitute_products
                          alt_names
                    }
                }
            }
        
            industries: allSanityGuaranteeIndustry(filter: {bygg: {eq: true}}, sort: {fields: name,order: ASC}) {
                edges {
                    node {
                        name
                        bygg
                        slug {
                            current
                        }
                    }
                }
            }
        }
    `)

  return(
    <Layout
        location={location}
        >
      <SEO
        title={pageContext.name + ' | Tilbyder av garantier & garantiforsikring'}
        description={(pageContext.name + " er en tilbyder av garantier og garantiforsikringer i det norske markedet.")}
        image={((pageContext.image && pageContext.image.asset)?pageContext.image.asset.url:"")}
        pathname={pageContext.slug}
      />
        <Header
          product={"garanti"}
          setFormActive={setFormActive}
        />
        <main className="site-wrapper-reveal">
            <Hero
                subtitle={"Tilbyder av garantiprodukter og forsikringsgarantier:"}
                title={(pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]:pageContext.name}
                description={pageContext.intro}
                image={((pageContext.image && pageContext.image.asset)?pageContext.image.asset:"")}
                product={"garanti"}
                setFormActive={setFormActive}
                disableButton={true}
            />

          {formActive &&
            <ProductForm
              setFormActive={setFormActive}
              formTitle={"Få tilbud på forsikringsgaranti"}
              formDesc={"Fyll ut skjemaet nedenfor samt beskriv det konkrete garantibehovet nedenfor kort. Avhengig av kompleksitet tar vi kontakt for ytterligere detaljer før vi innhenter tilbud fra aktuelle leverandører i markedet."}
              formActive={formActive}
            />
          }


          <div style={{backgroundColor:'#ececec'}}>
          <section style={{maxWidth:'1200px',marginLeft:'auto', marginRight:'auto'}}>
            <Assurance
              product={"garanti"}
              setFormActive={setFormActive}
            />
          </section>

          <AboutServiceWrap>
            <AboutArea
              industries={data.industries}
              products={data.products}
              setFormActive={setFormActive}
            />

          </AboutServiceWrap>
          </div>

        </main>


        <Footer
          product={"forsikrings-garanti"}
          setFormActive={setFormActive}
        />
    </Layout>
  )
}

export default Provider
